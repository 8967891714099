import { signIn } from 'next-auth/react';


export default function AuthCard() {
    return (
        <div style={{ maxWidth: 400 }} className="card">
            <div className="card-image">
                <figure className="image mt-4">
                    <img src="/qcify-logo.png" alt="Placeholder image" />

                </figure>
            </div>
            <div className="card-content">
                <div className="media">
                    <div>
                        <figure className="image">
                            <img src="/aip-logo.png" alt="Placeholder image" />
                        </figure>
                    </div>
                </div>

                <div className="content">
                    <button
                        onClick={() => {signIn('azure-ad');}}
                        className="button is-info is-fullwidth">
                        Log in
                    </button>
                </div>
            </div>
        </div>
    );
}