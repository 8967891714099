import { useSession } from 'next-auth/react';
import AuthCard from '@/components/auth/AuthCard';
import { useRouter } from 'next/router';

export default function Home() {
    const router = useRouter();
    const { data: session } = useSession();
    if (session) {
        router.push('/products');
    }
    return (
        <div className="content mt-6 is-flex is-justify-content-center">
            <AuthCard />
        </div>

    );
}
